/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 9, 2018 */



@font-face {
    font-family: 'poppinsblack';
    src: url('poppins-black-webfont.woff2') format('woff2'),
         url('poppins-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsblack_italic';
    src: url('poppins-blackitalic-webfont.woff2') format('woff2'),
         url('poppins-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsbold';
    src: url('poppins-bold-webfont.woff2') format('woff2'),
         url('poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsbold_italic';
    src: url('poppins-bolditalic-webfont.woff2') format('woff2'),
         url('poppins-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsextrabold';
    src: url('poppins-extrabold-webfont.woff2') format('woff2'),
         url('poppins-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsextrabold_italic';
    src: url('poppins-extrabolditalic-webfont.woff2') format('woff2'),
         url('poppins-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsextralight';
    src: url('poppins-extralight-webfont.woff2') format('woff2'),
         url('poppins-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsextralight_italic';
    src: url('poppins-extralightitalic-webfont.woff2') format('woff2'),
         url('poppins-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsitalic';
    src: url('poppins-italic-webfont.woff2') format('woff2'),
         url('poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight';
    src: url('poppins-light-webfont.woff2') format('woff2'),
         url('poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinslight_italic';
    src: url('poppins-lightitalic-webfont.woff2') format('woff2'),
         url('poppins-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsmedium';
    src: url('poppins-medium-webfont.woff2') format('woff2'),
         url('poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}